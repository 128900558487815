import React from 'react';
import { Route, Switch, Router } from '@swiftcarrot/react-router';
import $0 from "/Users/wangzuo/go/src/github.com/swiftcarrot/automation/lavr-prod/builds/app/packages/ccch9051/src/routes/_index.js";
import $1 from "/Users/wangzuo/go/src/github.com/swiftcarrot/automation/lavr-prod/builds/app/packages/ccch9051/src/routes/discover.js";
import $2 from "/Users/wangzuo/go/src/github.com/swiftcarrot/automation/lavr-prod/builds/app/packages/ccch9051/src/routes/_app.js";
import $3 from "/Users/wangzuo/go/src/github.com/swiftcarrot/automation/lavr-prod/builds/app/packages/ccch9051/src/routes/metaverse.js";
import $4 from "/Users/wangzuo/go/src/github.com/swiftcarrot/automation/lavr-prod/builds/app/packages/ccch9051/src/routes/_auth/register.js";
import $5 from "/Users/wangzuo/go/src/github.com/swiftcarrot/automation/lavr-prod/builds/app/packages/ccch9051/src/routes/_auth/login.js";
import $6 from "/Users/wangzuo/go/src/github.com/swiftcarrot/automation/lavr-prod/builds/app/packages/ccch9051/src/routes/index.js";
import $7 from "/Users/wangzuo/go/src/github.com/swiftcarrot/automation/lavr-prod/builds/app/packages/ccch9051/src/routes/watch.js";
import $8 from "/Users/wangzuo/go/src/github.com/swiftcarrot/automation/lavr-prod/builds/app/packages/ccch9051/src/routes/_auth.js";
import $9 from "/Users/wangzuo/go/src/github.com/swiftcarrot/automation/lavr-prod/builds/app/packages/ccch9051/src/routes/_app/review.js";
import $10 from "/Users/wangzuo/go/src/github.com/swiftcarrot/automation/lavr-prod/builds/app/packages/ccch9051/src/routes/_app/admin/assignments/$id.submissions.js";
import $11 from "/Users/wangzuo/go/src/github.com/swiftcarrot/automation/lavr-prod/builds/app/packages/ccch9051/src/routes/_app/admin/assignments/$id.stories.js";
import $12 from "/Users/wangzuo/go/src/github.com/swiftcarrot/automation/lavr-prod/builds/app/packages/ccch9051/src/routes/_app/admin/assignments/$id.reviews.js";
import $13 from "/Users/wangzuo/go/src/github.com/swiftcarrot/automation/lavr-prod/builds/app/packages/ccch9051/src/routes/_app/admin/classrooms/$id.js";
import $14 from "/Users/wangzuo/go/src/github.com/swiftcarrot/automation/lavr-prod/builds/app/packages/ccch9051/src/routes/_app/_dashboard/profile.js";
import $15 from "/Users/wangzuo/go/src/github.com/swiftcarrot/automation/lavr-prod/builds/app/packages/ccch9051/src/routes/_app/_dashboard/assignments/$id.peer.js";
import $16 from "/Users/wangzuo/go/src/github.com/swiftcarrot/automation/lavr-prod/builds/app/packages/ccch9051/src/routes/_app/_dashboard/assignments/$id.stories.js";
import $17 from "/Users/wangzuo/go/src/github.com/swiftcarrot/automation/lavr-prod/builds/app/packages/ccch9051/src/routes/_app/_dashboard/assignments/$id.reviews.js";
import $18 from "/Users/wangzuo/go/src/github.com/swiftcarrot/automation/lavr-prod/builds/app/packages/ccch9051/src/routes/_app/_dashboard/assignments/$id.review_stories.js";
import $19 from "/Users/wangzuo/go/src/github.com/swiftcarrot/automation/lavr-prod/builds/app/packages/ccch9051/src/routes/_app/_dashboard/new-story.js";
import $20 from "/Users/wangzuo/go/src/github.com/swiftcarrot/automation/lavr-prod/builds/app/packages/ccch9051/src/routes/_app/_dashboard/dashboard.js";
import $21 from "/Users/wangzuo/go/src/github.com/swiftcarrot/automation/lavr-prod/builds/app/packages/ccch9051/src/routes/_app/_dashboard/new-classroom.js";
import $22 from "/Users/wangzuo/go/src/github.com/swiftcarrot/automation/lavr-prod/builds/app/packages/ccch9051/src/routes/_app/_dashboard.js";
import $23 from "/Users/wangzuo/go/src/github.com/swiftcarrot/automation/lavr-prod/builds/app/packages/ccch9051/src/routes/_app/editor.js";
import $24 from "/Users/wangzuo/go/src/github.com/swiftcarrot/automation/lavr-prod/builds/app/packages/ccch9051/src/routes/_app/session.$key.js";
import $25 from "/Users/wangzuo/go/src/github.com/swiftcarrot/automation/lavr-prod/builds/app/packages/ccch9051/src/routes/_app/admin.js";

export default function Routes() {
  return React.createElement(Router, null,
    React.createElement(Switch, null,
    React.createElement(Route, { path: "/", component: $0, layouts: [] }),
    React.createElement(Route, { path: "/discover", component: $1, layouts: [] }),
    React.createElement(Route, { path: "/metaverse", component: $3, layouts: [] }),
    React.createElement(Route, { path: "/register", component: $4, layouts: [$8] }),
    React.createElement(Route, { path: "/login", component: $5, layouts: [$8] }),
    React.createElement(Route, { path: "/index", component: $6, layouts: [] }),
    React.createElement(Route, { path: "/watch", component: $7, layouts: [] }),
    React.createElement(Route, { path: "/review", component: $9, layouts: [$2] }),
    React.createElement(Route, { path: "/admin/assignments/:id/submissions", component: $10, layouts: [$2, $25] }),
    React.createElement(Route, { path: "/admin/assignments/:id/stories", component: $11, layouts: [$2, $25] }),
    React.createElement(Route, { path: "/admin/assignments/:id/reviews", component: $12, layouts: [$2, $25] }),
    React.createElement(Route, { path: "/admin/classrooms/:id", component: $13, layouts: [$2, $25] }),
    React.createElement(Route, { path: "/profile", component: $14, layouts: [$2, $22] }),
    React.createElement(Route, { path: "/assignments/:id/peer", component: $15, layouts: [$2, $22] }),
    React.createElement(Route, { path: "/assignments/:id/stories", component: $16, layouts: [$2, $22] }),
    React.createElement(Route, { path: "/assignments/:id/reviews", component: $17, layouts: [$2, $22] }),
    React.createElement(Route, { path: "/assignments/:id/review_stories", component: $18, layouts: [$2, $22] }),
    React.createElement(Route, { path: "/new-story", component: $19, layouts: [$2, $22] }),
    React.createElement(Route, { path: "/dashboard", component: $20, layouts: [$2, $22] }),
    React.createElement(Route, { path: "/new-classroom", component: $21, layouts: [$2, $22] }),
    React.createElement(Route, { path: "/editor", component: $23, layouts: [$2] }),
    React.createElement(Route, { path: "/session/:key", component: $24, layouts: [$2] }),
    React.createElement(Route, { path: "/admin", component: $25, layouts: [$2] }))
  );
}