import React, { Fragment } from "react";
import {
  Form,
  FormGroup,
  Select,
  Button,
  FormControl,
  FormConsumer,
  FormSubmit,
  Audio,
} from "components";
import Upload from "./Upload";
import SceneSelect from "./SceneSelect";
import { imageURL } from "src/utils";
import { analytics } from "src/analytics";

export default function EntityForm({
  setActiveEntityID,
  entity,
  scene,
  scenes,
  onDelete,
  ...props
}) {
  function validate(values) {
    const errors = {};

    if (values.type === "scene" && !values.scene) {
      errors.scene = "Please select destination";
    }

    return errors;
  }

  const handlePaste = (e) => {
    const text = e.clipboardData.getData("text");

    analytics.track("Paste Object Text", {
      scene_id: scene.id,
      entity_id: entity.id,
      text,
    });
  };

  return (
    <Form key={entity.id} initialValues={entity} validate={validate} {...props}>
      <div className="flex items-center border-b h-[50px] px-3">
        <Button
          className="mr-auto"
          variant="link"
          onClick={() => setActiveEntityID(null)}
        >
          Cancel
        </Button>
        <Button variant="secondary-mini" onClick={onDelete}>
          Delete
        </Button>
      </div>

      <div className="p-3">
        <FormGroup name="type" label="Object Type" component={Select}>
          <option value="media">Media</option>
          <option value="scene">Tour Button</option>
        </FormGroup>
        <FormGroup
          name="title"
          label="Object Title"
          tips="Add a object title to find it easily"
        />
        <FormConsumer>
          {({ getValue, setValue }) => {
            const type = getValue("type");
            const audio = getValue("audio");
            const image = getValue("image");

            if (type === "media") {
              return (
                <Fragment>
                  <FormGroup
                    name="text"
                    label="Text"
                    multiline
                    minRows={3}
                    onPaste={handlePaste}
                  />
                  <FormGroup
                    name="image"
                    label="Image"
                    component={Upload}
                    type="ENTITY_IMAGE"
                    accept="image/png,image/jpg"
                  >
                    {({ upload }) =>
                      upload ? (
                        <img
                          alt="object"
                          src={imageURL(upload.url, {
                            width: 512,
                            height: 384,
                          })}
                        />
                      ) : (
                        <div>
                          <div className="cursor-pointer">
                            Click to upload image
                          </div>
                          {image ? (
                            <img
                              alt="object"
                              src={imageURL(image.url, {
                                width: 512,
                                height: 384,
                              })}
                            />
                          ) : null}
                        </div>
                      )
                    }
                  </FormGroup>
                  {image ? (
                    <Button
                      variant="secondary-mini"
                      onClick={() => setValue("image", null)}
                    >
                      Delete Image
                    </Button>
                  ) : null}

                  <FormGroup
                    name="audio"
                    label="Audio"
                    component={Upload}
                    type="ENTITY_AUDIO"
                    accept="audio/mpeg,audio/ogg,audio/wav"
                  >
                    {() => (
                      <div className="cursor-pointer">
                        Click to upload audio
                      </div>
                    )}
                  </FormGroup>
                  {audio ? (
                    <div>
                      <FormControl
                        name="volume"
                        src={audio.url}
                        component={AudioInput}
                      />
                      <Button
                        variant="secondary-mini"
                        onClick={() => setValue("audio", null)}
                      >
                        Delete Audio
                      </Button>
                    </div>
                  ) : null}
                </Fragment>
              );
            } else if (type === "scene") {
              return (
                <FormGroup
                  name="scene"
                  label="Destination"
                  component={SceneSelect}
                  scenes={scenes.filter((s) => s.id !== scene.id)}
                />
              );
            }

            return null;
          }}
        </FormConsumer>
        <FormSubmit component={Button} variant="primary-block" className="mt-3">
          Save Object
        </FormSubmit>
      </div>
    </Form>
  );
}

function AudioInput({ value, onChange, ...props }) {
  return <Audio volume={value} onVolumeChange={onChange} {...props} />;
}
