import React from "react";
import { Form, FormSubmit } from "@swiftcarrot/react-form";
import { gql, useMutation } from "@apollo/client";
import { useAuth } from "@swiftcarrot/react-auth";
import { useLocation } from "@swiftcarrot/react-router";
import { FormGroup, Button } from "components";
import { captureException } from "src/sentry";
import { analytics } from "src/analytics";
import { client } from "src/client";

export default function LoginForm() {
  const [, navigate] = useLocation();
  const { setCurrentUser } = useAuth();
  const [login] = useMutation(LOGIN);

  function handleSubmit(values) {
    return login({ variables: { input: values } })
      .then((resp) => {
        const { token, user } = resp.data.login;
        analytics.identify(user.id);
        analytics.track("Login", { email: values.email });
        window.localStorage.setItem("token", token);
        analytics.identify(user.id);

        return client
          .query({
            query: gql`
              query AuthorizeChat {
                authorizeChat {
                  token
                }
              }
            `,
          })
          .then((resp) => {
            user.aiToken = resp.data.authorizeChat.token;
            setCurrentUser(user);
            navigate("/dashboard", { replace: true });
          });
      })
      .catch((err) => {
        captureException(err);
        alert("Login failed");
      });
  }

  return (
    <Form initialValues={{ email: "", password: "" }} onSubmit={handleSubmit}>
      <FormGroup name="email" label="Username" />
      <FormGroup name="password" label="Password" type="password" />
      <div className="form-submit">
        <FormSubmit component={Button} variant="primary">
          Log in
        </FormSubmit>
      </div>
    </Form>
  );
}

const LOGIN = gql`
  mutation Login($input: LoginInput!) {
    login(input: $input) {
      user {
        id
        name
        email
        isAdmin
        avatarURL
      }
      token
    }
  }
`;
