import { imageURL } from "ccch9051/src/utils";

export const ASSETS = [
  ["img", "closeIcon", "/images/close_icon.png"],
  ["img", "entityTexture", "/images/plane_entity.png"],
  ["img", "tourTexture", "/images/ball_tour_white.png"],
  ["img", "audioIcon", "/images/audioIcon.png"],

  // { tag: 'audio', props: { src:"/audios/click.wav", id: 'click', preload: 'auto' } },
  // { tag: 'audio', props: { src:"/audios/over.wav", id: 'over', preload: 'auto' } }

  // { tag: "img", props: { src: "/images/closeIcon.svg", id: "closeIcon", preload: 'auto' }},
  // { tag: "img", props: { src: "/images/scrolldwn.png", id: "scrolldwnIcon", preload: 'auto' } },
  // { tag: "img", props: { src: "/images/scrollup.jpeg", id: "scrollupIcon", preload: 'auto' } },
  // { tag: 'img', props: { src:"/images/plane_audio.png", id: 'audioTexture', preload: 'auto' } },
  // { tag: 'img', props: { src:"/images/plane_entity.png", id: 'entityTexture', preload: 'auto' } },
  // { tag: 'img', props: { src:"/images/ball_tour_white.png",id: 'tourTexture', preload: 'auto' } },
  // { tag: 'img',  props: { src:"/images/plane_image.png", id: 'imageTexture', preload: 'auto' } },

  // { tag: 'audio', props: { src:"/audios/click.wav", id: 'click', preload: 'auto' } },
  // { tag: 'audio', props: { src:"/audios/over.wav", id: 'over', preload: 'auto' } }
];

export function createAssetElement(asset) {
  let tag = asset[0];
  let $el = document.createElement(tag);
  $el.id = asset[1];
  $el.setAttribute("src", asset[2]);
  $el.setAttribute("preload", "auto");
  $el.setAttribute("crossorigin", "anonymous"); // TODO: check this
  return $el;
}

export function createStoryAssets(story) {
  const assets = [];
  story.scenes.forEach((s) => {
    if (s.imageURL) {
      assets.push([
        "img",
        "scene-image-" + s.index,
        imageURL(s.imageURL, {
          width: 7680,
          height: 4320,
        }),
      ]);
    }
    if (s.musicURL) {
      assets.push(["audio", "scene-music-" + s.index, s.musicURL]);
    }
    if (s.narrationURL) {
      assets.push(["audio", "scene-narration-" + s.index, s.narrationURL]);
    }

    s.entities.forEach((e) => {
      if (e.imageURL) {
        assets.push([
          "img",
          "entity-image-" + e.index,
          // TODO: bigger?
          imageURL(e.imageURL, {
            width: 1024 * 2,
            height: 768 * 2,
          }),
        ]);
      }
      if (e.audioURL) {
        assets.push(["audio", "entity-audio-" + e.index, e.audioURL]);
      }
    });
  });
  return assets;
}
