import React, { useState, useEffect, Fragment } from "react";
import { isEmpty } from "lodash-es";
import clsx from "clsx";
import { Loading, Placeholder } from "components";
import { SearchResult } from "./SearchResult";
import { imageURL } from "ccch9051/src/utils";
import { analytics } from "ccch9051/src/analytics";
import { captureException } from "ccch9051/src/sentry";

export const Recommendation = ({ story, scene, onChange }) => {
  const [{ loading, data, error }, setState] = useState({
    loading: true,
    data: null,
    error: null,
  });
  const [selected, setSelected] = useState({});

  const toggleTag = ({ key, category }) => {
    if (selected[key]) {
      const { [key]: _, ...newSelected } = selected;
      setSelected(newSelected);

      analytics.track("BGM Recommendation Unselect Tag", {
        story_id: story.id,
        scene_id: scene.id,
        tag: { key, category },
      });
    } else {
      setSelected({ ...selected, [key]: category });
      analytics.track("BGM Recommendation Select Tag", {
        story_id: story.id,
        scene_id: scene.id,
        tag: { key, category },
      });
    }
  };

  const params = {
    q: "",
    genres: new Set(
      Object.keys(selected).filter((k) => selected[k] === "genre"),
    ),
    moods: new Set(Object.keys(selected).filter((k) => selected[k] === "mood")),
    instruments: new Set(
      Object.keys(selected).filter((k) => selected[k] === "instrument"),
    ),
    themes: new Set(
      Object.keys(selected).filter((k) => selected[k] === "theme"),
    ),
  };

  useEffect(() => {
    const imageUrl = scene.imageURL
      ? imageURL(scene.imageURL, { width: 1000, height: 1000 })
      : null;
    const script = story.script.trim() ? story.script.trim() : null;

    setState({ loading: true, data: [], error: null });
    setSelected({});

    if (imageUrl || script) {
      fetch("https://wangzuo--playground-run-bgm.modal.run", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          image_url: imageUrl,
          script: script,
        }),
      })
        .then((r) => r.json())
        .then((data) => {
          analytics.track("BGM Recommendation Tags Loaded", {
            story_id: story.id,
            scene_id: scene.id,
            data,
          });

          setState({ loading: false, data: data.filters, error: null });
        })
        .catch((err) => {
          captureException(err);
        });
    } else {
      setState({ loading: false, data: [], error: null });
    }
  }, [story, scene]);

  return loading ? (
    <div className="flex flex-col gap-3 items-center justify-center">
      <Loading />
      <div className="px-4">
        Please be patient as the system loading your recommendations. It may
        take a while.
      </div>
    </div>
  ) : error ? (
    <div>Network Error</div>
  ) : data.length ? (
    <Fragment>
      <div className="bg-sky-100 p-3 rounded mb-3">
        <div className="text-sm">
          Based on your scene background image and narration script, the system
          recommends following <b>tags</b> for background music:
        </div>
        <Suggestion>
          {data.map((tag) => (
            <div key={tag.value}>
              <SuggestionOption
                onClick={() =>
                  toggleTag({ key: tag.value, category: tag.type })
                }
                active={selected[tag.value]}
              >
                {tag.type}: {tag.value}
              </SuggestionOption>
              <div className="p-1 text-sm cursor-pointer line-clamp-2 hover:line-clamp-none">
                {tag.reason}
              </div>
            </div>
          ))}
        </Suggestion>
      </div>
      {isEmpty(selected) ? (
        <Placeholder title="Click recommended tags to search music" />
      ) : (
        <SearchResult
          source="Recommendation"
          params={params}
          story={story}
          scene={scene}
          onChange={onChange}
        />
      )}
    </Fragment>
  ) : (
    <Placeholder title="No recommendation is generated for your narration script." />
  );
};

const Suggestion = ({ children }) => {
  return (
    <div className="flex my-2">
      <div className="flex-1">{children}</div>
    </div>
  );
};

Suggestion.defaultProps = {
  selected: false,
};

const SuggestionOption = ({ children, active, ...props }) => {
  return (
    <div
      className={clsx(
        "inline-block border rounded-full text-sm font-bold px-3 mr-1 cursor-pointer",
        active ? "bg-gray-300" : "bg-white",
      )}
      {...props}
    >
      {children}
    </div>
  );
};
